.components-housing-association-residents {
  .search-and-new-ticket {
    display: flex;
    margin-bottom: 2rem;
    gap: 1.5rem;

    &>div {
      flex-grow: 1;
    }

  }
}