.components-housing-unit {
  .components-common-table {
    max-width: 600px;
  }

  @keyframes pulse {
    from {
      opacity: 1;
    }

    to {
      opacity: 0.3;
    }
  }

  h1 {
    margin-bottom: -0.5rem;
  }

  .top {
    display: flex;

    .left {
      flex-grow: 1;
    }
  }

  .employee-info {
    font-size: 1rem;
    display: flex;
    align-items: center;

    .label {
      font-weight: 500;
      margin-right: 10px;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }

      &::after {
        content: ':';
      }
    }
  }

  a {
    font-size: 1rem;
  }

  .tickets {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }

  .skeleton-h1 {
    width: 300px;
    background-color: #E3E6EC;
    animation: pulse 0.8s ease-in-out infinite alternate;
    height: 1rem;
    margin-top: 3.6875rem;
    color: transparent;
    margin-bottom: 0.375rem;
  }

  .skeleton-employee-info {
    padding: 0.375rem 0;

    .content {
      background-color: #E3E6EC;
      animation: pulse 0.8s ease-in-out infinite alternate;
      width: 200px;
      height: 1rem;
    }
  }

  .skeleton-h2 {
    margin-top: 4.4375rem;

    .content {
      width: 200px;
      height: 1.375rem;
      margin: 0.5625rem 0
    }
  }

  .subscriptions-row-header {
    border-bottom: 2px solid #262626;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center; 

    h2 {
      margin-bottom: 0 !important;
      display: inline-flex;
      gap: 0.25rem;
      button {
        width: 53px;
      }

      .person-name {
        display: inline-block;
        max-width: 215px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

  }

}