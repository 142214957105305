.privacy-declaration-e-bike {
  p {
    margin-bottom: 1rem;
  }
  ul {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    font-size: 1rem;

    li {
      line-height: 1.25em;
    }
  }
}